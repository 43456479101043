<template>
  <v-layout align-center class="def-color">
    <HTMLTextArea
      ref="pcrtextarea"
      :disabled="!session.active || spectator"
      :value.sync="text"
      :label="label"
      showAppend
      verticalMargin
      horizontalMargin
      enabledTabSwitchResponder
    >
      <IconButton
        slot="header-items"
        icon="fas fa-history"
        @clicked="$emit('history')"
        bgcolor="transparent"
        noYMargin
        noXMargin
        class="mx-1"
      ></IconButton>
      <template slot="append">
        <v-layout class="mr-4">
          <v-spacer></v-spacer>
          <AutosaveIcon
            v-if="autosave"
            class="mr-1"
            :value="status"
          ></AutosaveIcon>
          <PredefinedRecordMenu
            v-if="predefinedRecordsEnabled && !spectator"
            :field="field"
            @append="addRecords"
            clientOnly
          ></PredefinedRecordMenu>
        </v-layout>
      </template>
    </HTMLTextArea>
    <CardSpectatorButtons
      ref="specButtons"
      @copyRecord="() => onCopiedRecord(text)"
      @addRecord="() => $emit('updateField', value, session[value])"
      class="mr-4"
      :enableUpdateFieldButtons="true"
      v-if="spectator"
    ></CardSpectatorButtons>
  </v-layout>
</template>

<script>
import ModificationMixin from "@/mixins/ModificationMixin";

export default {
  mixins: [ModificationMixin],
  props: {
    session: {},
    value: {},
    label: {},
    spectator: {
      default: false,
    },
  },
  computed: {
    field() {
      if (this.session && this.session[this.value]) {
        return this.session[this.value];
      } else {
        return "";
      }
    },
    autosave() {
      return this.session && this.session.active && !this.spectator;
    },
    text: {
      get() {
        if (this.session && this.session[this.value]) {
          return this.session[this.value].value;
        } else {
          return "";
        }
      },
      set(value) {
        this.session[this.value].value = value;
      },
    },
    predefinedRecordsEnabled() {
      if (this.session && this.session[this.value]) {
        return this.session[this.value].predefined_records_enabled;
      } else {
        return false;
      }
    },
  },
  components: {
    IconButton: () => import("@/components/buttons/IconButton"),
    AutosaveIcon: () => import("@/components/AutosaveIcon.vue"),
    HTMLTextArea: () => import("@/components/inputs/HTMLTextArea"),
    PredefinedRecordMenu: () =>
      import("@/components/menus/PredefinedRecordMenu"),
    CardSpectatorButtons: () =>
      import("@/components/buttons/CardSpectatorButtons"),
  },
  methods: {
    onCopiedRecord(value) {
      this.$copyText(value, this.$refs.specButtons.$el);
      this.$emit("copiedField", "Wpis został skopiowany do schowka.");
    },
    addRecords(records) {
      records.forEach((record) => {
        this.text += `<p>${record}</p>`;
      });
    },
  },
  mounted() {
    if (this.autosave) {
      this.startWatching("text");
    }
  },
};
</script>

<style lang="scss" scoped>
.def-color {
  color: black;
}
</style>